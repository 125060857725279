<template>
  <div id="homepage">
    <!-- ======= Header ======= -->
    <header
      id="header"
      class="fixed-top d-flex align-items-center header-transparent"
    >
      <site-header />
    </header>
    <!-- End Header -->
    <!-- ======= Hero Section ======= -->
    <section
      v-if="isLoading"
      id="hero"
      :style="{'background-image': `url(${dataConfig ? `${$serverfile + dataConfig.banner}` : getBackground})`}"
      class="d-flex justify-cntent-center align-items-center"
    >
      <div
        class="container carousel carousel-fade"
      >
        <!-- Slide 1 -->
        <div class="carousel-item active">
          <div class="carousel-container">
            <h2 class="animate__animated animate__fadeInDown">
              {{ dataConfig.title }}
            </h2>
            <p class="animate__animated animate__fadeInUp">
              {{ dataConfig.titleContent }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- End Hero -->

    <main id="main">
      <section
        class="team"
        data-aos="fade-up"
        data-aos-easing="ease-in-out"
        data-aos-duration="500"
      >
        <div class="container">
          <div class="title-box">
            <h1 class="">
              Doanh nghiệp
            </h1>
            <h3>
              Thông tin về những doanh nghiệp trên hệ thống quản lý an toàn vệ
              sinh lao động
            </h3>
          </div>
          <div class="row box-text">
            <div
              v-for="item in dataBussinessList"
              :key="item.id"
              class="col-lg-4 col-md-6 d-flex align-items-stretch"
            >
              <div class="member">
                <div class="member-img">
                  <a @click="showDetail(item.id)">
                    <img
                      :src="item.avatar ? ($serverfile + '/' + item.avatar) : 'assets/img/enterprise/img_01.png'"
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                  <div class="social">
                    <a href=""><i class="bi bi-twitter"></i></a>
                    <a href=""><i class="bi bi-facebook"></i></a>
                    <a href=""><i class="bi bi-instagram"></i></a>
                    <a href=""><i class="bi bi-linkedin"></i></a>
                  </div>
                </div>
                <div class="member-info">
                  <a @click="showDetail(item.id)">
                    <h4>{{ item.name }}</h4>
                  </a>
                  <span>{{ item.bussinessType }}</span>
                  <div class="row">
                    <div class="col-1">
                      <i class="bi bi-geo-alt"></i>
                    </div>
                    <div class="col-11">
                      {{ item.address }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-1">
                      <i class="bi bi-telephone"></i>
                    </div>
                    <div class="col-11">
                      {{ item.phoneNumber }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btn-seemore">
            <button
              type="button"
              class="btn btn-warning"
              @click="enterprise"
            >
              Xem tất cả
            </button>
          </div>
        </div>
      </section>
      <!-- End Team Section -->

      <!-- ======= Danh sách kiểm định viên ======= -->
      <section class="services">
        <div class="container">
          <div class="title-box">
            <h1>Danh sách kiểm định viên</h1>
            <h3>
              Thông tin về kiểm định viên trên hệ thống quản lý an toàn vệ sinh
              lao động
            </h3>
          </div>
          <div class="row">
            <div
              v-for="(inspector,key) in dataInpectorList"
              :key="key"
              class="col-md-6 col-lg-3 d-flex align-items-stretch"
              data-aos="fade-up"
            >
              <div class="inspector-box-pink">
                <div>
                  <img
                    :src="inspector.avatar ? ($serverfile + inspector.avatar) : require('@/assets/images/avatars/12-small.png')"
                    class="avt-style"
                  />
                </div>

                <h4 class="title">
                  <a href="">{{ inspector.name }}</a>
                </h4>
                <p class="description">
                  {{ inspector.bussiness }}
                </p>
                <div class="icon">
                  <i class="bx bxl-dribbble">
                    <feather-icon
                      icon="BarChart2Icon"
                      size="24"
                      class="text-body color-decede"
                    />
                  </i>
                </div>
                <h2>{{ inspector.code }}</h2>
              </div>
            </div>

          </div>
          <div class="btn-seemore">
            <button
              type="button"
              class="btn btn-warning"
              @click="inspector"
            >
              Xem tất cả
            </button>
          </div>
        </div>
      </section>
      <!-- End Services Section -->

      <!-- ======= Why Us Section ======= -->
      <section
        v-if="dataNewsList && dataNewsList.length"
        class="why-us section-bg"
        data-aos="fade-up"
        date-aos-delay="200"
      >
        <div class="title-box">
          <h1>Tin tức</h1>
        </div>
        <div class="container news-container">
          <a
            v-for="item in dataNewsList"
            :key="item.id"
            class="row mr-0 ml-0 mb-1 bg-white"
            @click="gotoDetail(item.id)"
          >
            <div class="col-lg-4 p-0">
              <img
                :src="item.avatar ? ($serverfile + item.avatar) : imgDefault"
                class="img-fluid-new"
                alt=""
              />
            </div>

            <div class="col-lg-8 d-flex flex-column p-5">
              <h1>
                {{ item.name }}
              </h1>
              <div class="row mb-2 mt-1">
                <div class="col-5 d-flex">
                  <i class="bi bi-person mr-2"></i>
                  <div>{{ item.createdby }}</div>
                </div>

                <div class="col-3 d-flex">
                  <i class="bi bi-calendar-event mr-2"></i>
                  <div>{{ item.createdDate | formatDateToDDMM }}</div>
                </div>
              </div>
              <p>
                {{ item.description }}
              </p>
            </div>
          </a>
        </div>
        <div class="btn-seemore">
          <button
            type="button"
            class="btn btn-warning"
            @click="()=> {
              $router.push({name: 'news-home'})
            }"
          >
            Xem tất cả
          </button>
        </div>
      </section>
      <!-- End Why Us Section -->
    </main>
    <!-- End #main -->

    <!-- ======= Footer ======= -->
    <footer
      id="footer"
      data-aos="fade-up"
      data-aos-easing="ease-in-out"
      data-aos-duration="500"
    >
      <site-footer />

      <!-- <div class="container mt-1">
        <img src="@/assets/images/logo/logo-footer-bottom.png">
      </div> -->
    </footer>
    <!-- End Footer -->

    <!-- <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
    ><i class="bi bi-arrow-up-short"></i></a> -->
  </div>
</template>
<script src="./assets/js/main.js"></script>
<script>
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import './assets/vendor/aos/aos.js'
import ConstantsApi from './constants/ConstantsApi'
import { mapActions, mapGetters } from 'vuex'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import SiteHeader from './components/SiteHeader.vue'
import SiteFooter from './components/SiteFooter.vue'
import store from './store'

export default {
  components: {
    SiteHeader,
    SiteFooter
  },
  data() {
    return {
      tokenCheck: localStorage.getItem("accessToken"),
      roleUser: null,
      urlQuery: {
        key: '',
        pageSize: 4,
        pageNumber: 1,
      },
      dataNewsList: [],
      dataBussinessList: [],
      config: {},
      getBackground: require('@/assets/images/banner/IMG.png'),
      dataInpectorList: [],
      urlQueryNew: {
        pageSize: 1,
        pageNumber: 1,
      },
      imgDefault: store.imgDefault,
    };
  },
  filters: {
    formatDateToDDMM,
  },
  mounted() {
    /* eslint-disable no-undef */
    /* eslint-disable no-new */
    // eslint-disable-next-line func-names

    this.fetchNewsData(this.urlQueryNew)
    this.fetchBussinessData({pageSize: 6, pageNumber: 1})

    /**
     * Easy selector helper function
     */

    const select = (el, all = false) => {
      el = el.trim();
      if (all) {
        return [...document.querySelectorAll(el)];
      }
      return document.querySelector(el);
    };

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
      const selectEl = select(el, all);
      if (selectEl) {
        if (all) {
          selectEl.forEach((e) => e.addEventListener(type, listener));
        } else {
          selectEl.addEventListener(type, listener);
        }
      }
    };

    /**
     * Easy on scroll event listener
     */
    const onscroll = (el, listener) => {
      el.addEventListener("scroll", listener);
    };

    /**
     * Scrolls to an element with header offset
     */
    const scrollto = (el) => {
      const header = select("#header");
      let offset = header.offsetHeight;

      if (!header.classList.contains("header-scrolled")) {
        offset -= 20;
      }

      const elementPos = select(el).offsetTop;
      window.scrollTo({
        top: elementPos - offset,
        behavior: "smooth",
      });
    };

    /**
     * Toggle .header-scrolled class to #header when page is scrolled
     */
    const selectHeader = select("#header");
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 100) {
          selectHeader.classList.add("header-scrolled");
        } else {
          selectHeader.classList.remove("header-scrolled");
        }
      };
      window.addEventListener("load", headerScrolled);
      onscroll(document, headerScrolled);
    }

    /**
     * Back to top button
     */
    const backtotop = select(".back-to-top");
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add("active");
        } else {
          backtotop.classList.remove("active");
        }
      };
      window.addEventListener("load", toggleBacktotop);
      onscroll(document, toggleBacktotop);
    }

    /**
     * Mobile nav toggle
     */
    // eslint-disable-next-line func-names
    on("click", ".mobile-nav-toggle", function (e) {
      select("#navbar").classList.toggle("navbar-mobile");
      this.classList.toggle("bi-list");
      this.classList.toggle("bi-x");
    });

    /**
     * Mobile nav dropdowns activate
     */
    // eslint-disable-next-line func-names
    on(
      "click",
      ".navbar .dropdown > a",
      function (e) {
        if (select("#navbar").classList.contains("navbar-mobile")) {
          e.preventDefault();
          this.nextElementSibling.classList.toggle("dropdown-active");
        }
      },
      true
    );

    /**
     * Scrool with ofset on links with a class name .scrollto
     */
    // eslint-disable-next-line func-names
    on(
      "click",
      ".scrollto",
      function (e) {
        if (select(this.hash)) {
          e.preventDefault();

          const navbar = select("#navbar");
          if (navbar.classList.contains("navbar-mobile")) {
            navbar.classList.remove("navbar-mobile");
            const navbarToggle = select(".mobile-nav-toggle");
            navbarToggle.classList.toggle("bi-list");
            navbarToggle.classList.toggle("bi-x");
          }
          scrollto(this.hash);
        }
      },
      true
    );

    /**
     * Skills animation
     */
    const skilsContent = select(".skills-content");
    if (skilsContent) {
      // eslint-disable-next-line no-new
      new Waypoint({
        element: skilsContent,
        offset: "80%",
        handler(direction) {
          const progress = select(".progress .progress-bar", true);
          progress.forEach((el) => {
            el.style.width = `${el.getAttribute("aria-valuenow")}%`;
          });
        },
      });
    }

    /**
     * Testimonials slider
     */
    // eslint-disable-next-line no-new
    new Swiper(".testimonials-carousel", {
      speed: 400,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
    });

    /**
     * Porfolio isotope and filter
     */
    window.addEventListener("load", () => {
      const portfolioContainer = select(".portfolio-container");
      if (portfolioContainer) {
        // eslint-disable-next-line no-undef
        const portfolioIsotope = new Isotope(portfolioContainer, {
          itemSelector: ".portfolio-wrap",
          layoutMode: "fitRows",
        });

        const portfolioFilters = select("#portfolio-flters li", true);

        // eslint-disable-next-line func-names
        on(
          "click",
          "#portfolio-flters li",
          function (e) {
            e.preventDefault();
            portfolioFilters.forEach((el) => {
              el.classList.remove("filter-active");
            });
            this.classList.add("filter-active");

            portfolioIsotope.arrange({
              filter: this.getAttribute("data-filter"),
            });
            portfolioIsotope.on("arrangeComplete", () => {
              // eslint-disable-next-line no-undef
              AOS.refresh();
            });
          },
          true
        );
      }
    });

    /**
     * Initiate portfolio lightbox
     */
    // eslint-disable-next-line no-undef
    const portfolioLightbox = GLightbox({
      selector: ".portfolio-lightbox",
    });

    /**
     * Portfolio details slider
     */
    // eslint-disable-next-line no-new
    // eslint-disable-next-line no-undef
    new Swiper(".portfolio-details-slider", {
      speed: 400,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
    });

    /**
     * Animation on scroll
     */
    window.addEventListener("load", () => {
      // eslint-disable-next-line no-undef
      AOS.init({
        duration: 1000,
        easing: "ease-in-out",
        once: true,
        mirror: false,
      });
    });
  },
  computed: {
    ...mapGetters('app', ['dataConfig','isLoading']),
  },
  created() {
    if (this.$route.params.check === true) {
      this.reload()
    }
    this.getInspectorList()
    this.accountInfo()
    // this.getInfoConfig()
  },
  methods: {

    async getInspectorList(){
      const data = await store.getInspectorList(this.urlQuery)
        this.dataInpectorList = data?.data?.pageLists

    },

    // Lấy thông tin người dùng
    async accountInfo() {
      if (localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)) {
        const { data } = await useJwt.accountInfo()
        if (data.roleIdentity) {
          this.roleUser = data?.roleIdentity
        }
      }
    },

    async fetchNewsData(urlQuery) {
      const { data } = await axiosApiInstance.get(ConstantsApi.NEWS_GET_PAGING, {
        params: urlQuery,
      })
      this.dataNewsList = data?.data?.pageLists
    },
    async fetchBussinessData(urlQuery) {
      const { data } = await axiosApiInstance.get(ConstantsApi.BUSSINESS_GET_PAGING, {
        params: urlQuery,
      })
      this.dataBussinessList = data?.data?.pageLists
    },

    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
      localStorage.removeItem("userData");
      localStorage.removeItem("accessToken");
      this.$ability.update(initialAbility);
      this.$router.push({ name: "home" });
      this.$router.go();
    },
    reload() {
      setTimeout(this.$router.go(), 4000)
    },
    enterprise() {
      this.$router.push({ name: "enterprise-home" }).catch(() => {});
    },
    inspector() {
      this.$router.push({ name: "list-inspector-home" }).catch(() => {});
    },
    gotoDetail(id) {
      this.$router.push({ name: "news-detail-home",params: {id} }).catch(() => {});
    },
    showDetail(id){
      this.$router.push({ name: "enterprise-detail-home",params: {id} }).catch(() => {})
    }
  },
};
</script>
<style lang="scss">
#main {
  .container {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    margin-right: auto;
    margin-left: auto;
  }
}

.member{
  width: min-content;
}
.member-img {
  height: 200px;
  width: 350px;
}
.news-container {
  .img-fluid-new {
    height: 360px;
    object-fit: cover;
  }
}
.description {
  font-family: unset;
}
.title {
  font-family: unset;
}
.member {
  width: min-content;
}
.color-decede{
  color: #ff689b !important;
}

@media (min-width: 376px) and (max-width: 425px) {
.team {
  .member {
    width: 100%;
    margin-bottom: 20px;
    background: white;
    border: 1px solid #ededed;
  }
}

.member-img {
  height: 200px;
  width: 100%;
}
}
</style>
